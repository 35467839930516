.product-item {
    height: 350px; /* Set a fixed height */
    width: 100%; /* Adjust width based on container */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Make content spread evenly */
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}


.product-item img {
    height: 200px; /* Adjust image height */
    object-fit: cover; /* Ensure the image scales well without distortion */
}

.product-item .text-center {
    flex-grow: 1; /* Ensures the text stretches to fill available space */
    padding: 15px;
}
