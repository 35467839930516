/* Default styles */
.container-fluid {
    background-color: transparent;
    transition: background-color 0.3s ease, padding 0.3s ease;
  }
  
  /* Default navbar styles */
  .navbar {
    padding: 20px 50px;
    transition: background-color 0.3s ease, padding 0.3s ease;
  }
  
  /* When scrolled */
  .scrolled {
    background-color: #fff; /* Change to darker background */
    animation-delay: 0.1s; 
    animation-name: fadeIn;
    top: -45px;
  }
  
  .scrolled-navbar {
    padding: 10px 50px;  /* Reduce padding */
  }
  