/* Target even testimonial items */
.middle-item, .middle-item h5 {
    color: white;
    background-color: #3CB815 !important; /* Example: set a different background color for even items */
}

.middle-item i {
    color: #F65005 !important;
}


.testimonial-item img {
    width: 60px !important;
    height: 60px !important;
}